import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../shared/services/shared.service'

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private http: HttpClient, private sharedService:SharedService) {}
  private config = environment.apiEndPoints;
  private headerApiUrl = this.config.headerApiUrl;
  // private footerApiUrl = this.config.footerApiUrl;
  private verifyRefreeRegAPI =
    this.config.serverUrl +
    this.config.services.refereeService +
    this.config.endpoints.verifyRefreeRegAPI;
  private headerFooterSubject = new Subject();

  getHeaderFooterContent(callback : any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.http
      .get<any>(this.headerApiUrl, header)
      .pipe(retry(1))
      .subscribe(
        (response) => {
          this.headerFooterSubject.next(response);
          callback(response);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  verifyRefreeRegistration(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let fanCode = this.sharedService.fanCode;
    return this.http.get<any>(
      `${this.verifyRefreeRegAPI}?fanCode=${fanCode}`,
      header
    );
  }

  getHeaderFooterSubject() {
    return this.headerFooterSubject;
  }
}

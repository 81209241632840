import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { SharedService } from '../../shared/services/shared.service';
import {
  CfaMessageNote,
  CfaMessageNotification,
  IndividualProfile,
  DobModel,
} from '../../models/IndividualProfile.model';
import {
  LinkedAccounts,
  LinkedChildModal,
} from '../../models/linked-accounts.model';

import {
  GenderModal,
  NationalityModal,
  MedicalInfoModal,
  DisabilitySaveModal,
  DisabilityInfoModal,
} from '../../models/other-details.model';

import { IndividualCounty } from '../../models/county.model';
import { DbsSafeguarding } from '../../models/dbs-safeguarding.model';
import { Learning } from '../../models/learning.model';

import { EmergencyContact } from '../../models/emergencyContact.model';
import { PreferenceModal } from '../../models/preferences.model';
import { RefereeRegistrationConsent } from '../../models/referee-registration.model';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';

import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class IpsService {
  CfaMessageNoteRequest: CfaMessageNote = new CfaMessageNote();
  CfaMessageNotification: CfaMessageNotification = new CfaMessageNotification();
  dbsSafeguarding: DbsSafeguarding = new DbsSafeguarding();
  linkedChildModal: LinkedChildModal = new LinkedChildModal();
  linkedAccounts: LinkedAccounts = new LinkedAccounts();
  genderModalRequest: GenderModal = new GenderModal();
  nationalityModalRequest: NationalityModal = new NationalityModal();
  disabilityInfoModalRequest: DisabilityInfoModal = new DisabilityInfoModal();
  medicalInfoModalRequest: MedicalInfoModal = new MedicalInfoModal();
  preferenceModal: PreferenceModal = new PreferenceModal();
  dobRequest: DobModel = new DobModel();
  saveDisabilitySubscriptionValue: string;

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.saveDisabilitySubscriptionValue =
      environment.saveDisabilitiesSubscriptionValue;
  }
  private config = environment.apiEndPoints;
  private ipsServerUrl =
    this.config.serverUrl + this.config.services.ipsService;
  private ipsServerUrlAuthz =
    this.config.serverUrl + this.config.services.ipsAuthzService;
  private cfaMessageUpdateApiUrl =
    this.ipsServerUrl + this.config.endpoints.cfaUpdateMessage;

  private childCfaMessageUpdateApiUrl =
    this.ipsServerUrl + this.config.endpoints.childCFAUpdateMessage;

  private IndividualCountyAPI =
    this.ipsServerUrl + this.config.endpoints.IndividualCounty;

  private dbsSafeguardingAPI =
    this.ipsServerUrl + this.config.endpoints.dbsSafeguardingAPI;

  private faqhelpAPI = this.ipsServerUrl + this.config.endpoints.faqhelpAPI;

  private individualProfileApiUrl =
    this.ipsServerUrl + this.config.endpoints.individualProfile;

  private childIndividualProfileApiUrl =
    this.ipsServerUrl + this.config.endpoints.childIndividualProfile;
  private subscriptionValue = environment.subscriptionValue;

  private qualificationAPI =
    this.ipsServerUrl + this.config.endpoints.qualificationApi;

  private linkedAccountsAPI =
    this.ipsServerUrl + this.config.endpoints.linkedAccountsAPI;
  private updateLinkedChildAPI =
    this.ipsServerUrl + this.config.endpoints.updateLinkedChildAPI;
  private childLinkedAccount =
    this.ipsServerUrl + this.config.endpoints.childLinkedAccount;
  saveDisabilityApi = this.ipsServerUrl + this.config.endpoints.saveDisability;

  private genderUpdateApiUrl =
    this.ipsServerUrl + this.config.endpoints.genderUpdateApiUrl;
  private getGenderApiUrl =
    this.ipsServerUrl + this.config.endpoints.getGenderApiUrl;
  private getNationalityAPI =
    this.ipsServerUrl + this.config.endpoints.getNationalityAPI;

  private updateNationalityAPI =
    this.ipsServerUrl + this.config.endpoints.updateNationalityAPI;
  private updateMedicalInfoAPI =
    this.ipsServerUrl + this.config.endpoints.updateMedicalInfoAPI;
  private updatePlaceOfBirthAPI =
    this.ipsServerUrl + this.config.endpoints.updatePlaceOfBirth;
  private updateNameByFuzzySearchApiUrl =
    this.ipsServerUrl + this.config.endpoints.updateNameByFuzzySearch;

  private childUpdatePlaceOfBirthApiUrl =
    this.ipsServerUrl + this.config.endpoints.childUpdatePlaceOfBirth;
  private childupdateNationalityAPI =
    this.ipsServerUrl + this.config.endpoints.childUpdateNationality;
  private childUpdateGenderAPI =
    this.ipsServerUrl + this.config.endpoints.childUpdateGender;
  private saveDisabilityAPI =
    this.ipsServerUrl + this.config.endpoints.saveDisability;
  private childUpdateMedicalInfoAPI =
    this.ipsServerUrl + this.config.endpoints.childUpdateMedicalInfo;
  private childUpdateNameByFuzzySearchApiurl =
    this.ipsServerUrl + this.config.endpoints.childUpdateNameByFuzzySearch;
  private childUpdateAddressInfoAPI =
    this.ipsServerUrl + this.config.endpoints.childUpdateAddress;
  private preferenceAPI =
    this.ipsServerUrl + this.config.endpoints.preferencesAPI;
  private emarsysIdApi =
    this.ipsServerUrlAuthz + this.config.endpoints.preferncesEmarsysIdApi;
  private childRefereeRegistrationConsent =
    this.ipsServerUrl + this.config.endpoints.childRefereeConsent;
  private removeParentNotification =
    this.ipsServerUrl + this.config.endpoints.removeParentNotification;

  private updateRemoveParentNotification =
    this.ipsServerUrl + this.config.endpoints.updateRemoveParentNotification;

  private removeParentAccount =
    this.ipsServerUrl + this.config.endpoints.removeParentAccount;
  private deleteAccountapi =
    this.ipsServerUrl + this.config.endpoints.deleteAccountApi;
  private removeLinkedIndividual =
    this.ipsServerUrl + this.config.endpoints.removeParentAccount;

  private dobUpdateApi = this.ipsServerUrl + this.config.endpoints.updateDobAPI;
  private dobUpdateChildApi = this.ipsServerUrl + this.config.endpoints.childUpdateDob;

  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  //functions

  updateCFAMessage(message: string, fancode: number) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    var fan: any = fancode;
    this.CfaMessageNoteRequest.FanCode = fan;
    this.CfaMessageNoteRequest.Message = message;
    this.CfaMessageNoteRequest.NotificationSectionId = 1;
    this.CfaMessageNoteRequest.NotificationSection = 'PreferredName';

    if (this.sharedService.isChildAccount === 'true') {
      return this.http.post<CfaMessageNote>(
        `${this.childCfaMessageUpdateApiUrl}`,
        this.CfaMessageNoteRequest,
        header
      );
    } else {
      return this.http.post<CfaMessageNote>(
        `${this.cfaMessageUpdateApiUrl}`,
        this.CfaMessageNoteRequest,
        header
      );
    }
  }

  getIndividualCounty(fanCode: string): Observable<IndividualCounty> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<IndividualCounty>(
      `${this.IndividualCountyAPI}?fanCode=${fanCode}`,
      header
    );
  }

  updateIndividualCounty(data: any): Observable<IndividualCounty> {
    return this.http.post<IndividualCounty>(
      `${this.IndividualCountyAPI}`,
      data
    );
  }

  getDbsSafeguarding(fanCode: string): Observable<DbsSafeguarding> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<DbsSafeguarding>(
      `${this.dbsSafeguardingAPI}?fanCode=${fanCode}`,
      header
    );
  }

  getHelperQA(): any {
    const header = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': `${this.subscriptionValue}`,
        'Content-Type': 'text/html',
        'Cache-Control':
          'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0',
      }),
    };

    return this.http.get(`${this.faqhelpAPI}?ProductID=2`, header);
  }

  getB2CHelperQA(): any {
    const header = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': `${this.subscriptionValue}`,
        'Content-Type': 'text/html',
        'Cache-Control':
          'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0',

      }),
    };

    return this.http.get(`${this.faqhelpAPI}?ProductID=3`, header);
  }

  getIndividualProfile(fanCode: string): Observable<IndividualProfile> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<IndividualProfile>(
      `${this.individualProfileApiUrl}?fanCode=${fanCode}`,
      header
    );
  }
  getChildIndividualProfile(fanCode: string): Observable<IndividualProfile> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<IndividualProfile>(
      `${this.childIndividualProfileApiUrl}?fanCode=${fanCode}`,
      header
    );
  }

  getQualifications(fanCode: string): Observable<Learning> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<Learning>(
      `${this.qualificationAPI}?fanCode=${fanCode}`,
      header
    );
  }

  getSafeguardingQualifications(fanCode: string): Observable<Learning> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<Learning>(
      `${this.qualificationAPI}?fanCode=${fanCode}&sourceTab=SafeguardingTab`,
      header
    );
  }

  getLinkedAccounts(fanCode: string): Observable<LinkedAccounts> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .get<LinkedAccounts>(
        `${this.linkedAccountsAPI}?fanCode=${fanCode}`,
        header
      )
      .pipe(catchError(this.handleError));
  }

  getChildLinkedAccounts(fanCode: string): Observable<LinkedAccounts> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .get<LinkedAccounts>(
        `${this.childLinkedAccount}?fanCode=${fanCode}`,
        header
      )
      .pipe(catchError(this.handleError));
  }

  updateLinkedChildAccount(childData: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.linkedChildModal.FanCode = Number(this.sharedService.fanCode);
    this.linkedChildModal.FirstName = childData.firstName;
    this.linkedChildModal.LastName = childData.lastName;
    this.linkedChildModal.DateofBirth =
      childData.month + '/' + childData.day + '/' + childData.year;
    return this.http
      .put<LinkedChildModal>(
        `${this.updateLinkedChildAPI}`,
        this.linkedChildModal,
        header
      )
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return of(error);
  }

  saveDisabilites(
    disabilities: DisabilitySaveModal[],
    fancode: any,
    parentFancode: any
  ): Observable<DisabilityInfoModal> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${this.saveDisabilitySubscriptionValue}`,
        'Cache-Control':
          'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0',
      }),
    };
    this.disabilityInfoModalRequest.FanCode = Number(fancode);
    this.disabilityInfoModalRequest.ParentFanCode = Number(parentFancode);
    this.disabilityInfoModalRequest.DisabilityTypes = disabilities;

    return this.http.put<DisabilityInfoModal>(
      `${this.saveDisabilityApi}`,
      this.disabilityInfoModalRequest,
      header
    );
  }

  updateGender(
    genderValue: { gender: any },
    fancode: string
  ): Observable<GenderModal> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.genderModalRequest.FanCode = Number(fancode);
    this.genderModalRequest.GenderId = Number(genderValue.gender);

    return this.http.put<GenderModal>(
      `${this.genderUpdateApiUrl}`,
      this.genderModalRequest,
      header
    );
  }
  ChildUpdateGender(
    genderValue: { gender: any },
    fancode: string
  ): Observable<GenderModal> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.genderModalRequest.FanCode = Number(fancode);
    this.genderModalRequest.GenderId = Number(genderValue.gender);

    return this.http.put<GenderModal>(
      `${this.childUpdateGenderAPI}`,
      this.genderModalRequest,
      header
    );
  }
  getGenderDetails() {
    var fanCode = this.sharedService.fanCode;
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<GenderModal>(
      `${this.getGenderApiUrl}?fanCode=${fanCode}`,
      header
    );
  }
  updateNationality(data: string | any[], fancode: string) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.nationalityModalRequest.FanCode = Number(fancode);
    if (data.length == 0) this.nationalityModalRequest.NationalityId = '';
    else this.nationalityModalRequest.NationalityId = Number(data[0].ID);

    return this.http.put<GenderModal>(
      `${this.updateNationalityAPI}`,
      this.nationalityModalRequest,
      header
    );
  }
  childUpdateNationality(data: string | any[], fancode: string) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.nationalityModalRequest.FanCode = Number(fancode);
    if (data.length == 0) this.nationalityModalRequest.NationalityId = '';
    else this.nationalityModalRequest.NationalityId = Number(data[0].ID);

    return this.http.put<GenderModal>(
      `${this.childupdateNationalityAPI}`,
      this.nationalityModalRequest,
      header
    );
  }

  getNationalityDetails(fancode: string) {
    var fanCode = fancode;
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${this.getNationalityAPI}?fanCode=${fanCode}`,
      header
    );
  }
  updateMedicalInfo(message: string) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.medicalInfoModalRequest.FanCode = Number(this.sharedService.fanCode);
    this.medicalInfoModalRequest.MedicalInfo = message;

    return this.http.put<GenderModal>(
      `${this.updateMedicalInfoAPI}`,
      this.medicalInfoModalRequest,
      header
    );
  }

  childUpdateMedicalInfo(message: string) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.medicalInfoModalRequest.FanCode = Number(
      this.sharedService.childFancode
    );
    this.medicalInfoModalRequest.MedicalInfo = message;

    return this.http.put<GenderModal>(
      `${this.childUpdateMedicalInfoAPI}`,
      this.medicalInfoModalRequest,
      header
    );
  }
  updatePlaceOfBirth(placeOfBirthData: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.updatePlaceOfBirthAPI}`,
      placeOfBirthData,
      header
    );
  }
  
  //update child address API call
  updateChildAddress(data:any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put(
      `${this.childUpdateAddressInfoAPI}`,
      data,
      header
    );
  }
  childUpdatePlaceOfBirth(placeOfBirthData: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.childUpdatePlaceOfBirthApiUrl}`,
      placeOfBirthData,
      header
    );
  }

  saveNameByFuzzySearch(data: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    if (this.sharedService.isChildAccount === 'true') {
      return this.http.put(
        this.childUpdateNameByFuzzySearchApiurl,
        data,
        header
      );
    } else {
      return this.http.put(this.updateNameByFuzzySearchApiUrl, data, header);
    }
  }

  updateOtherNumber(data: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put(
      `${this.config.serverUrl +
      this.config.services.ipsService +
      this.config.endpoints.otherNumberApi
      }`,
      data,
      header
    );
  }

  updateEmergencyContact(data: any, isChildAccount: boolean) {
    let updateEmergencyContactApi;

    if (!isChildAccount) {
      updateEmergencyContactApi =
        this.config.serverUrl +
        this.config.services.ipsService +
        this.config.endpoints.updateEmergencyContact;
    } else {
      updateEmergencyContactApi =
        this.config.serverUrl +
        this.config.services.ipsService +
        this.config.endpoints.childEmergencyContactUpdate;
    }
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put(`${updateEmergencyContactApi}`, data, header);
  }

  updateChildEmail(data: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put(
      `${this.config.serverUrl +
      this.config.services.ipsService +
      this.config.endpoints.childEmailUpdate
      }`,
      data,
      header
    );
  }
  getEmergencyContact(
    fanCode: string,
    isChildAccount: boolean
  ): Observable<EmergencyContact> {
    let emergencyContactApi;

    if (!isChildAccount) {
      emergencyContactApi =
        this.config.serverUrl +
        this.config.services.ipsService +
        this.config.endpoints.emergencyContact;
    } else {
      emergencyContactApi =
        this.config.serverUrl +
        this.config.services.ipsService +
        this.config.endpoints.childEmergencyContact;
    }
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .get<EmergencyContact>(
        `${emergencyContactApi}?fanCode=${fanCode}`,
        header
      )
      .pipe(catchError(this.handleError));
  }

  getPreference(fanCode: string): Observable<PreferenceModal> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<PreferenceModal>(
      `${this.preferenceAPI}?fanCode=${fanCode}`,
      header
    );
  }

  updatePreference(data: any): Observable<PreferenceModal> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let preferenceRequest: PreferenceModal = {
      AcceptedFAGroupMarketing: data,
      FanCode: Number(this.sharedService.fanCode),
    };
    return this.http.put<PreferenceModal>(
      `${this.preferenceAPI}`,
      preferenceRequest,
      header
    );
  }
  getEmarsysId(email: string): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(`${this.emarsysIdApi}?emailId=${email}`, header);
  }
  updateRefereeRegistrationConsent(refereeConsent: boolean): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let consentRequest: RefereeRegistrationConsent = {
      RefRegConsent: refereeConsent,
      FanCode: Number(this.sharedService.childFancode),
    };
    return this.http.put<any>(
      `${this.childRefereeRegistrationConsent}`,
      consentRequest,
      header
    );
  }

  getRemoveParentNotification(fanCode: number): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(
      `${this.removeParentNotification}?fanCode=${fanCode}`,
      header
    );
  }

  postNotificationStatus(data: any): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${this.updateRemoveParentNotification}`,
      data,
      header
    );
  }

  removeParentAccountRequest(data: any): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(`${this.removeParentAccount}`, data, header);
  }

  deleteAccount(emailaddress: string): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const data = { email: emailaddress };
    return this.http.post<any>(`${this.deleteAccountapi}`, data, header);
  }

  UpdateDateOfBirth(dateDob: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.dobRequest.DateOfBirth = dateDob.month + '/' + dateDob.day + '/' + dateDob.year;
    if (this.sharedService.isChildAccount == 'true') {
      this.dobRequest.FanCode = Number(this.sharedService.childFancode);
      return this.http.put(`${this.dobUpdateChildApi}`, this.dobRequest, header);
    } else {
      this.dobRequest.FanCode = Number(this.sharedService.fanCode);
      return this.http.put(`${this.dobUpdateApi}`, this.dobRequest, header);
    }
  }

  removeLinkedIndividualRequest(data: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(`${this.removeLinkedIndividual}`, data, header);

  }
}

import { Component } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { b2cPolicies } from 'src/environments/constants';
import { Router } from '@angular/router';
import { SharedService } from './shared/services/shared.service';
import * as shajs from 'sha.js';
import { B2CUserAccountService } from './shared/microservices/b2cuseraccount.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  title = 'AccountMgmtWebUI';
  private readonly _destroying$ = new Subject<void>();
  httpSubscriptions: any[] = [];

  constructor(
    private msalService: MsalService,
    private readonly broadcastService: MsalBroadcastService,
    private readonly router: Router,
    private sharedService: SharedService,
    private b2CUserAccountService: B2CUserAccountService,
  ) {}

  ngOnInit() {
    this.handleMsalRedirect();
    this.handleMsalEvents();
  }

  ngOnDestroy(): void {
    this.httpSubscriptions.forEach((s: any) => s.unsubscribe());
    this._destroying$.next();
    this._destroying$.complete();
  }

  handleMsalRedirect() {
    let msalRedirectSubscription = this.msalService
      .handleRedirectObservable()
      .subscribe({
        next: (result: AuthenticationResult) => {
          // this block is for a successful redirect from the B2C page

          const accounts: any = this.msalService.instance.getAllAccounts();
          console.log('msal instance get all accounts', accounts);
          this.getUserDetails(accounts);
          this.updateGoogleAnalytics(result);

          let policyName: any;
          if (result && result.account && result.account.idTokenClaims) {
            policyName = result.account.idTokenClaims.acr;
            console.log('policy name in msal', policyName);
            if (policyName.includes('b2c_1a_settingspff_str')) {
              localStorage.setItem('settings', 'authentication');
            }
          }
          if (localStorage.getItem('settings') == 'changePassword') {
            localStorage.setItem('settings', 'changePasswordSuccess');
          }
        },
        error: (error: AuthError) => {
          // this block is for an error redirect from the B2C page\
          console.log('error in msal', error);
          // if error is not null, something went wrong
          if (error) {
            if (localStorage.getItem('settings') == 'changePassword') {
              localStorage.setItem('settings', 'changePasswordError');
            }
            localStorage.setItem('isB2Cupdate', 'false');           
            window.location.reload();           
          }
        },
      });
    this.httpSubscriptions.push(msalRedirectSubscription);
  }

  handleMsalEvents() {
    const msalEventSubscription = this.broadcastService.msalSubject$
      .pipe(
        filter((msg: any) =>
          [
            EventType.ACQUIRE_TOKEN_SUCCESS,
            EventType.LOGIN_SUCCESS,
            EventType.LOGIN_FAILURE,
            EventType.ACQUIRE_TOKEN_FAILURE,
          ].includes(msg.eventType)
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        switch (result.eventType) {
          case EventType.LOGIN_SUCCESS: {
            let payload: any = <AuthenticationResult>result.payload;
            if (
              payload.idTokenClaims?.acr?.toLowerCase() ===
              environment.b2cSettings.passwordResetPolicy.toLowerCase()
            ) {
              console.log('Password has been reset successfully.');
              this.msalService.logout();
            }
            break;
          }
          case EventType.LOGIN_FAILURE: {
            if (
              result.error?.message?.includes(
                b2cPolicies.errorCodes.forgotPassword
              )
            ) {
              console.log('Starting the flow for reset password');
              localStorage.clear();
              this.router.navigateByUrl('/forgotPassword');
            } else {
              localStorage.clear();
              this.msalService.logout();
            }
            break;
          }
          case EventType.ACQUIRE_TOKEN_SUCCESS: {            
            let payload: any = <AuthenticationResult>result.payload;
            this.sharedService.token = payload.idToken;
            break;
          }
          case EventType.ACQUIRE_TOKEN_FAILURE: {           
            console.log("token failure");
            sessionStorage.clear();
            localStorage.clear();
            this.msalService.logout();
            break;
          }
        }
      });
    this.httpSubscriptions.push(msalEventSubscription);
  }

  // Get the user details from the JWT
  getUserDetails(accounts: any): void {
    const details: any = this.getTokenDetails(accounts);
    if (details.fan) {
      this.sharedService.fanCode = details.fan;
      this.sharedService.email = details.email;
      this.sharedService.name = details.name;
      this.sharedService.signInNameFromToken = details.signInNameFromToken;
      this.sharedService.userId = details.userId;      
      this.sharedService.lastLogOnTime = details.lastLogOnTime; 
      this.checkForValidSession();  
    } else {
      console.log('invalid token details');
    }
  }

  // get the user details from the JWT
  getTokenDetails(accounts: any): any {
    let getCurrentAccount = () => {
      let accountDetails: any;
      accounts.forEach((acc: any) => {
        if (acc.idTokenClaims && acc.idTokenClaims._userProfile) {
          accountDetails = acc;
        }
      });
      return accountDetails;
    };

    try {
      let account: any = getCurrentAccount();
      return {
        name: account.idTokenClaims.name,
        fan: account.idTokenClaims._userProfile,
        email: account.idTokenClaims.signInName.includes('@')
          ? account.idTokenClaims.signInName
          : account.idTokenClaims.email,
        signInNameFromToken: account.idTokenClaims.signInName,
        userId: (account.idTokenClaims.sub) ? account.idTokenClaims.sub : '',        
        lastLogOnTime: account.idTokenClaims.lastLogonTime 
      };
    } catch (e) {
      return {};
    }
  }

  checkForValidSession(){
      // for global logout
      this.b2CUserAccountService.validateSignInSession().subscribe((response:any) => {
        if(response && response.status === 404){
          localStorage.clear();
          this.msalService.logout();
        }
    }) 
  }

  updateGoogleAnalytics(response: any) {
    let windowObj: any = window;
    console.log('response in google analytics', response);
    if (
      response &&
      response.account &&
      response.account.idToken &&
      response.account.idToken.acr
    ) {
      let policyName: string = response.account.idToken.acr;

      if (policyName.includes('b2c_1a_fasignupcustomotppff_struiver')) {
        windowObj['dataLayer'].push({
          event: 'user_signup',
          user_login_type: policyName,
          user_login_ID: shajs('sha256')
            .update(response.account.idToken.signInName)
            .digest('hex'),
        });
      } else if (policyName.includes('b2c_1a_signup_signinact')) {
        windowObj['dataLayer'].push({
          event: 'user_login',
          user_login_type: policyName,
          user_login_ID: shajs('sha256')
            .update(response.account.idToken.signInName)
            .digest('hex'),
        });
      } else if (
        policyName.includes(
          'b2c_1a_signup_signinprofileeditcustomotpfancodev3js'
        )
      ) {
        windowObj['dataLayer'].push({
          event: 'user_login',
          user_login_type: policyName,
          user_login_ID: shajs('sha256')
            .update(response.account.idToken.email)
            .digest('hex'),
        });
      }
    }
  }
}

import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { IpsService } from '../../microservices/ips.service';
import { CRSservice } from '../../microservices/crs.service';
import { SharedService } from '../shared.service';
import { Injectable, Injector } from '@angular/core';
import { EnableDisableHeaderLinkService } from '../../../shared/utilities/enableDisableHeaderLinks.service';
import { CheckYouthService } from '../../../shared/utilities/check-youth.service';
import { map, catchError } from 'rxjs/operators';
import { UserDataService } from '../../utilities/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class IndividualProfileResolverService {
  userDataService:any;
  checkYouthService:any;
  constructor(private ipsService: IpsService,
    private crsservice: CRSservice,
    private sharedService: SharedService,
    private enableDisableHeaderLinkService: EnableDisableHeaderLinkService,
    private router:Router, 
    private injector: Injector) { 
      this.userDataService = injector.get<UserDataService>(UserDataService);
      this.checkYouthService = injector.get<CheckYouthService>(CheckYouthService);
    }

  // Disable preferences for youth accounts on load 
  checkYouth() {
    let dateOfBirth = new Date(this.sharedService.dob);
    if (this.checkYouthService.isYouth(dateOfBirth)) {
      let that = this;
      let intervalObj = setInterval(function () {
        if ($('ul.global-navigation__primary-menu').find('#preferences').html() != undefined) {
          that.enableDisableHeaderLinkService.disablePreferenceLinks();
          clearInterval(intervalObj);
        }
      }, 1000);
    }
    else {
      let that = this;
      let intervalObj = setInterval(function () {
        if ($('ul.global-navigation__primary-menu').find('#preferences').html() != undefined) {
          that.enableDisableHeaderLinkService.enablePreferenceLinks();
          clearInterval(intervalObj);
        }
      }, 1000);
    }
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    //redirect to diversity and inclusion page if user is coming from edi page
    if(localStorage.getItem('app') == 'edi') {
      localStorage.setItem('app', '')
      this.router.navigateByUrl('/diverstyInclusion');      
    } 
    this.userDataService.getUserDetails();
    let fanCode: any;
    if (route.queryParamMap.get('childAccount')) {
      this.sharedService.isChildAccount = 'true';
      this.sharedService.childFancode = route.queryParamMap.get('childAccount') || '';
      fanCode = this.sharedService.childFancode;

      //Header change password link disable for child
      let that = this;
      let intervalObj = setInterval(function () {
        if ($('ul.global-navigation__primary-menu').find('#preferences').html() != undefined) {
          that.enableDisableHeaderLinkService.disableChangePasswordLinks();
          that.enableDisableHeaderLinkService.disablePreferenceLinkForChildView();
          that.enableDisableHeaderLinkService.disableLearningLinkForChildView();
          that.enableDisableHeaderLinkService.disableSafeguardingLinkForChildView();
          that.enableDisableHeaderLinkService.disableDNILinkForChildView();
          clearInterval(intervalObj);
        }
      }, 1000);

    }
    else {
      fanCode = this.sharedService.fanCode;
      this.sharedService.isChildAccount = 'false';
      //enable header links for parents
      this.enableDisableHeaderLinkService.enableChangePasswordLinks();
      this.enableDisableHeaderLinkService.enableLearningLink();
      this.enableDisableHeaderLinkService.enableSafeguardingLink();
      this.enableDisableHeaderLinkService.enableDiversityAndInclusionLinks();
      if (this.sharedService.dob != undefined && this.sharedService.dob != null && this.sharedService.dob != "") {
        this.checkYouth();
      }
    }

    let getInvidualProfile;
    if (this.sharedService.isChildAccount == 'true') {
      getInvidualProfile = this.ipsService.getChildIndividualProfile(fanCode)
        .pipe(
          map(res => res),
          catchError(error => {
            // do something with the error
            return of({ error: error })
          }))
    }
    else {
      getInvidualProfile = this.ipsService.getIndividualProfile(fanCode)
        .pipe(
          map(res => res),
          catchError(error => {
            // do something with the error
            return of({ error: error })
          }))
    }
    let getInvidualRole = this.crsservice.getIndividualProfileRole(fanCode)
    .pipe(
      map(res => res),
      catchError(error => {
        // do something with the error
        return of({ error: error })
      }))
    return forkJoin([getInvidualProfile, getInvidualRole])
  }
}

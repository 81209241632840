import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnableDisableHeaderLinkService {

  
    enableChangePasswordLinks() {
        $('ul.global-navigation__primary-menu').find('#settings').closest('a').removeClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#settings').closest('a').attr('href', '#account-management/settings');
        $('#changePassToolTip').remove();
        $('ul.global-navigation__primary-menu').find('#settings').closest('a').removeAttr('onClick');
        $('ul.global-navigation__primary-menu').find('#settings').closest('a span').removeAttr('onClick');
    }

    enablePreferenceLinks() {
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').removeClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').attr('href', '#account-management/preferences');
        $('#preferenceToolTip').remove();
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').removeAttr('onClick');
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a span').removeAttr('onClick');
    }   

    enableDiversityAndInclusionLinks() {
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').removeClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').attr('href', '#account-management/diversityAndInclusion/diversity?callingApp=my-account');
        $('#diversityAndInclusionToolTip').remove();
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').removeAttr('onClick');
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a span').removeAttr('onClick');
    }

    enableLearningLink(){
        $('ul.global-navigation__primary-menu').find('#learning').closest('a').removeClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#learning').closest('a').attr('href', '#account-management/learning');   
    }

    enableSafeguardingLink(){
        $('ul.global-navigation__primary-menu').find('#dbs-safeguarding').closest('a').removeClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#dbs-safeguarding').closest('a').attr('href', '#account-management/dbs-safeguarding');   
    }     

    disableChangePasswordLinks() {
        let changePassToolTip = '<div id="changePassToolTip" class="changepass-menu-tooltip" role="tooltip"><p tabindex="0">you cannot manage a user’s settings on behalf of someone else.<br><br></p><button role="button" tabindex="0" aria-label="Got it - Close change password tooltip" class="dbs-button" onclick="javascript:$(\'#changePassToolTip\').hide();" onKeypress="javascript:$(\'#changePassToolTip\').hide();">GOT IT</button></div>';
        $('ul.global-navigation__primary-menu').find('#settings').closest('a').addClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#settings').closest('a').attr('href', '#').on( "click", function() {
            return false
          });
        $('ul.global-navigation__primary-menu').find('#settings').closest('li').append(changePassToolTip);
        $('ul.global-navigation__primary-menu').find('#settings').closest('a').attr('onClick', '$(\'#changePassToolTip\').show();');
        $('ul.global-navigation__primary-menu').find('#settings').closest('a span').attr('onClick', '$(\'#changePassToolTip\').show();');
    }

    disablePreferenceLinks() {
        let preferenceToolTip = '<div id="preferenceToolTip" class="preference-menu-tooltip" role="tooltip"><p tabindex="0">Preferences cannot be set on an individual under 16 years of age.<br><br></p><button role="button" tabindex="0" aria-label="Got it - Close preference tooltip" class="dbs-button" onclick="javascript:$(\'#preferenceToolTip\').hide();" onKeypress="javascript:$(\'#preferenceToolTip\').hide();">GOT IT</button></div>';
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').addClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').attr('href', '#').on( "click", function() {
            return false
          });
        $('ul.global-navigation__primary-menu').find('#preferences').closest('li').append(preferenceToolTip);
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').attr('onClick', '$(\'#preferenceToolTip\').show();');
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a span').attr('onClick', '$(\'#preferenceToolTip\').show();');
    }

    disableDiversityAndInclusionLinks() {
        let diversityAndInclusionToolTip = '<div id="diversityAndInclusionToolTip" class="diversityAndInclusion-menu-tooltip" role="tooltip"><p tabindex="0">You need to be 16 years or older to provide this information.<br><br></p><button role="button" tabindex="0" aria-label="Got it - Close diversity and inclusion tooltip" class="dbs-button" onclick="javascript:$(\'#diversityAndInclusionToolTip\').hide();" onKeypress="javascript:$(\'#diversityAndInclusionToolTip\').hide();">GOT IT</button></div>';
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').addClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').attr('href', '#').on( "click", function() {
            return false
          });
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('li').append(diversityAndInclusionToolTip);
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').attr('onClick', '$(\'#diversityAndInclusionToolTip\').show();');
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a span').attr('onClick', '$(\'#diversityAndInclusionToolTip\').show();');
    }

    disablePreferenceLinkForChildView() {
        let preferenceToolTip = '<div id="preferenceToolTip" class="preference-menu-tooltip" role="tooltip"><p tabindex="0">Preferences cannot be set on behalf of someone else.<br><br></p><button role="button" tabindex="0" aria-label="Got it - Close preference tooltip" class="dbs-button" onclick="javascript:$(\'#preferenceToolTip\').hide();" onKeypress="javascript:$(\'#preferenceToolTip\').hide();">GOT IT</button></div>';
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').addClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').attr('href', '#').on( "click", function() {
            return false
          });
        $('ul.global-navigation__primary-menu').find('#preferences').closest('li').append(preferenceToolTip);
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a').attr('onClick', '$(\'#preferenceToolTip\').show();');
        $('ul.global-navigation__primary-menu').find('#preferences').closest('a span').attr('onClick', '$(\'#preferenceToolTip\').show();');
    }

    disableLearningLinkForChildView(){
        $('ul.global-navigation__primary-menu').find('#learning').closest('a').addClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#learning').closest('a').attr('href', '#').on( "click", function() {
            return false
          });  
    }

    disableSafeguardingLinkForChildView(){
        $('ul.global-navigation__primary-menu').find('#dbs-safeguarding').closest('a').addClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#dbs-safeguarding').closest('a').attr('href', '#').on( "click", function() {
            return false
          });  
    }

    disableDNILinkForChildView() {        
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').addClass('child-disable');
        $('ul.global-navigation__primary-menu').find('#diversityAndInclusion').closest('a').attr('href', '#').on( "click", function() {
            return false
          });
       
    }
}

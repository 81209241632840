import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { b2cPolicies } from '../../../environments/constants';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
    selector: 'app-check-policy',
    template: '<h5 class="loading-text"> Please wait...</h5>',
    styles: ['.loading-text{ margin-top: 30px; margin-left:20px; }']
})

export class CheckPolicyComponent {
    type = '';

    constructor(private msalService: MsalService,       
        private readonly route: ActivatedRoute,
        private sharedService:SharedService ) {
        this.type = this.route.snapshot.data.type; 
    }

    async ngOnInit() {        
        await this.msalService.instance.initialize();
        this.checkRoute();
    }

    // decide the action based on type
    checkRoute(): void {
        const url = `${environment.b2cSettings.authUrl}/${environment.b2cSettings.tenant}`;
        switch (this.type) {
            case b2cPolicies.signup: {
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.signUpPolicyFA}`
                });
                break;
            }
            case b2cPolicies.logout: {
                localStorage.clear()
                this.msalService.logout();
                break;
            }
             case b2cPolicies.forgottenemail: {
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.forgottenEmailPolicy}`
                });
                break;
            }
             case b2cPolicies.forgotPassword: {
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.passwordResetPolicy}`
                });
                break;
            } 
            case b2cPolicies.dontknowmyfan: {
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.dontKnowMyFanPolicy}`
                });
                break;
            } 
            case b2cPolicies.authenticateUser: {
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.authenticateUserForSettingsPolicy}`
                });
                break;
            } 
            case b2cPolicies.updatePassword: {
                const policy = (this.sharedService.fanCode == this.sharedService.signInNameFromToken) ? environment.b2cSettings.updatePasswordPolicyForFancode : environment.b2cSettings.updatePasswordPolicy;
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${policy}`
                });
                break;
            } 
            case b2cPolicies.updateEmail: {                
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.updateSignInEmailPolicy}`
                });
                break;
            } 
            case b2cPolicies.updateMobile: {                
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.updateMobilePolicy}`
                });
                break;
            } 
            case b2cPolicies.updateAddress: {                
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.userAddressPolicy}`
                });
                break;
            } 
            case b2cPolicies.login:
            default: {
                this.msalService.loginRedirect({
                    scopes: [environment.b2cSettings.clientID],
                    extraQueryParameters: environment.b2cSettings.extraQueryParameters,
                    authority: `${url}/${environment.b2cSettings.signInPolicy}`
                });
                break;
            }
        }
    }
}

import { Injectable, Inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DOCUMENT } from "@angular/common";


@Injectable({
    providedIn : 'root'
})
export class CssResolver {
    constructor( @Inject(DOCUMENT) private document: Document){

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(route.url)
        if (route.url[0].path === 'diversityAndInclusion'){
                if( $('link[id="styles-ac"]')){
                $('link[id="styles-ac"]').remove()  
                $('.fa-navigation .global-navigation__primary-list-item').removeClass('current-section');
                $('#diversityAndInclusion').closest('li.global-navigation__primary-list-item').addClass('current-section'); 
                const head = this.document.getElementsByTagName("head")[0];
                const style = this.document.createElement("link");
                style.rel = "stylesheet";
                style.href= "../../../assets/account-management/diversity.css";
                style.id = "diversity"            
                head.appendChild(style);        
            }
        }else{      
            let ele = document.getElementById("diversity-inclusion");
            if(ele) {
                ele.style.display = 'none';
            }
            if( this.document.getElementById("styles-ac")){
                console.log("styles-ac is already loaded")
                $('link[id="styles-edi"]').remove() 
                $('link[id="diversity"]').remove() 
            }else{
                const head = this.document.getElementsByTagName("head")[0];
                const style = this.document.createElement("link");
                style.rel = "stylesheet";
                style.href= "../../../assets/account-management/styles-ac.css";
                style.id = "styles-ac"            
                head.appendChild(style);
                $('link[id="styles-edi"]').remove() 
                $('link[id="diversity"]').remove()     
            }
            
        }
    

    }

}

import { Injectable } from '@angular/core';
import {LoaderService} from './loader.service';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError,finalize } from 'rxjs/operators';

import {environment} from '../../../../src/environments/environment';


@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
    
     public http409 = false;
    constructor( private loaderService:LoaderService ){
                    
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
        const subscriptionKey = environment.subscriptionKey;
        const subscriptionValue = this.setSubscriptionValues(request.url);       
           request = request.clone({
           headers: request.headers.set(subscriptionKey,subscriptionValue),
           //headers: request.headers.set('Authorization',`Bearer ${this.authSandbox.getToken()}`),
            setHeaders:{ 
              /*   'Authorization': `Bearer ${this.msalInit.getToken()}`, */
                'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0',            
         
            }
        });
        this.loaderService.show();
        return next.handle(request)
            .pipe(
                
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                        console.log(errorMessage);
                    } else {
                        // server-side error
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        console.log(errorMessage);
                       
                    }
                    
             
                    // else{
                    //     window.alert(errorMessage);
                    // }
                    return throwError(error);
                    //return;
                }),
               finalize(() => this.loaderService.hide())
            );
    }

    
setSubscriptionValues(url:string): string{
    let subscriptionValue = environment.subscriptionValue; 
    environment.otherSubscriptions.forEach(service => {
        if(url.includes(service.serviceUrl)){
            subscriptionValue = service.subscriptionValue;
        }
    });
    return subscriptionValue;
}

}


import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SharedService } from "../services/shared.service";


@Injectable({
  providedIn: 'root'
})

export class B2CUserAccountService {
  private config = environment.apiEndPoints;
  private globalLogoutApiUrl =
  this.config.serverUrl + this.config.services.b2cuseraccount + this.config.endpoints.globalLogoutAPi;
  private validateUserSessionUrl = this.config.serverUrl + this.config.services.b2cuseraccount + this.config.endpoints.validateUserSession;
  constructor(private http: HttpClient, private sharedService: SharedService) {
   
  }
  globalLogout() {
    let request={
      userId: this.sharedService.userId
    }

    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'      
      }),
    };

    return this.http.post(
      `${this.globalLogoutApiUrl}`,
      request,
      header
    );
  }

  validateSignInSession(){
    let request={
      userId:this.sharedService.userId,      
      lastLogOnTime:this.sharedService.lastLogOnTime
    }

    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    return this.http.post(
      `${this.validateUserSessionUrl}`,
      request,
      header
    );
  }
}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountManagementModule } from './modules/account-management/account-management.module';
import { CheckPolicyComponent } from './components/b2c-policy/check-policy.component';
import { HttpClientInterceptor } from './shared/services/http-client.interceptor';

//msal

import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory, 
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './shared/services/msal-config.service';
import { NationalityComponent } from './shared/dialog-components/nationality/nationality.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PlaceofbirthComponent } from './shared/dialog-components/place-of-birth/place-of-birth.component';
import { GenderComponent } from './shared/dialog-components/gender/gender.component';
import { MedicalinformationComponent } from './shared/dialog-components/medical-information/medical-information.component';
import { OtherNumberComponent } from './shared/dialog-components/other-number/other-number.component';
import { DobEditComponent } from './shared/dialog-components/dob-edit/dob-edit.component';

import { EmergencyContactComponent } from './shared/dialog-components/emergency-contact/emergency-contact.component';
import { EmergencyContactNumberComponent } from './shared/dialog-components/emergency-contact-number/emergency-contact-number.component';
import { ChildEmailComponent } from './shared/dialog-components/child-email/child-email.component';
import { EditChildAddressComponent } from './shared/dialog-components/edit-child-address/edit-child-address.component';
import { NameEditComponent } from './shared/dialog-components/name-edit/name-edit.component';
import { AddIndividualComponent } from './shared/dialog-components/add-individual/add-individual.component';
import { HelperComponent } from './components/helper/helper.component';
import { DisabilityComponent } from './shared/dialog-components/disability/disability.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SettingComponent } from './shared/dialog-components/setting/setting.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PhotoEditComponent } from './shared/dialog-components/photo-edit/photo-edit.component';
import { OtherCountiesComponent } from './shared/dialog-components/otherCounties/other-counties.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CountiesFilterPipe } from './shared/services/counties-filter.pipe';

import { DiversityComponent } from './components/diversity/diversity.component'


@NgModule({

  declarations: [AppComponent, CountiesFilterPipe, CheckPolicyComponent, NationalityComponent, PlaceofbirthComponent, GenderComponent, MedicalinformationComponent, OtherNumberComponent, EmergencyContactComponent, EmergencyContactNumberComponent, NameEditComponent, ChildEmailComponent,EditChildAddressComponent, AddIndividualComponent, HelperComponent, DobEditComponent, DisabilityComponent, SettingComponent, PhotoEditComponent, OtherCountiesComponent, DiversityComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MsalModule,
    AccountManagementModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,



  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(private sharedService: SharedService, private msalService:MsalService){

  } 

  // get msal account instances and extract token details from the logged in account details
  getUserDetails(): void {
    const accounts: any = this.msalService.instance.getAllAccounts();
    const details: any = this.getTokenDetails(accounts);
    if (details.fan) {
      this.sharedService.fanCode = details.fan;
      this.sharedService.email = details.email;
      this.sharedService.name = details.name;
      this.sharedService.signInNameFromToken = details.signInNameFromToken;
      this.sharedService.userId = details.userId;      
      this.sharedService.lastLogOnTime = details.lastLogOnTime; 
    } else {
      console.log('invalid token details');
    }
  }

   // get the user details from the JWT
   getTokenDetails(accounts: any): any {
    let getCurrentAccount = () => {
      let accountDetails: any;
      accounts.forEach((acc: any) => {
        if (acc?.idTokenClaims?._userProfile) {
          accountDetails = acc;
        }
      });
      return accountDetails;
    };

    try {
      let account: any = getCurrentAccount();
      return {
        name: account.idTokenClaims.name,
        fan: account.idTokenClaims._userProfile,
        email: account.idTokenClaims.signInName.includes('@')
          ? account.idTokenClaims.signInName
          : account.idTokenClaims.email,
        signInNameFromToken: account.idTokenClaims.signInName,
        userId: (account.idTokenClaims.sub) ? account.idTokenClaims.sub : '',        
        lastLogOnTime: account.idTokenClaims.lastLogonTime 
      };
    } catch (e) {
      return {};
    }
  } 
}
export class DbsSafeguarding {
  Fan!: number;
  IndividualProfileId!: number;
  DbsDetails!: DbsDetails;
  SafeguardingDetails!: SafeguardingDetails;
}

export class SafeguardingDetails {
  IndividualSafeguardingId!: number;
  CommitteeSafeguardingWorkshopDate!: Date;
  CommitteeSafeguardingWorkshopExpiryDate!: Date;
  SafeguardingForAllDate!: Date;
  SafeguardingForAllExpiryDate!: Date;
  SafeguardingWorkshopDate!: Date;
  SafeguardingWorkshopExpiryDate!: Date;
  WelfareOfficerWorkshopDate!: Date;
}

export class DbsDetails {
  DbscertificateDate!: Date;
  DbsexpiryDate?: Date;
  DbsStatusName!: string;
  DBSPortalStatusName!: string;
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  fanCode = ''; // Fan of the logged in user
  email = ''; // Email of the logged in user
  name = ''; // User name of the logged in user
  dob = ''; // dob of logged in user
  childFancode = ''; //fan code of the current visible child individual
  signInNameFromToken = '';
  token = "";
  userId=''; //sub bvalue retrieved from token,
  lastLogOnTime=''; //sub bvalue retrieved from token
  isChildAccount = 'false';
  app = '';
  callingApp = '';
  callingAppUrl = '';
}

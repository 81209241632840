export class LinkedAccounts {
  IndividualFan!: number;
  ListLinkedProfile!: ListLinkedProfile[];
}

export class ListLinkedProfile {
  Fan!: number;
  FirstName!: string;
  LastName!: string;
  MiddleName!: string; 
  LegalName?: string;
  DateOfBirth!: Date;
  EmailAddress?: string;
  RelationshipId!: number;
  RelationshipType!: string;
  IsActive!: boolean;
  StartDate!: string;
  ExpiryDate!: string;
  FullName?:string;
  isYouth?:boolean;
  isFakeEmail?:boolean;
  showEmailAlert?:boolean;
}

export class LinkedChildModal {
  FanCode!: number; 
  FirstName!: string;
  LastName!: string;
  DateofBirth!: string;
}
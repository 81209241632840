export const endpoints =
 {    
    individualProfile: "api/profile/individual/details",
    updateNameByFuzzySearch: "api/profile/manage/updatenamebyfuzzysearch",
    deleteAccountApi: "api/profile/manage/deleteUser",
    dbsSafeguardingAPI: "api/Profile/Individual/DbsSafeguarding/Details",
    linkedAccountsAPI: "api/Profile/Individual/LinkedAccounts",
    downloadImageApiUrl: "api/Media/Download",
    uploadImageApiUrl: "api/Media/Upload",
    deleteImageApiUrl: "api/Media/Delete",
    capsApi: "v2/api/individualactivity/GetCaps",
    capsTableUrl: "http://www.thefa.com/england/mens-seniors/capstable",
    otherNumberApi: "api/profile/manage/phone/other",
    cfaUpdateMessage: "api/profile/notification/user/notes",
    cfaNotification: "api/Notification/NotificationRequest",
    individualProfileRole:"v2/api/individualroles",
    updateDobAPI: "api/profile/manage/dateofbirth",
    preferencesAPI: "api/profile/individual/marketingpreference",
    preferncesEmarsysIdApi: "v1/api/individual/emarsysid",
    qualificationApi: "api/profile/individual/individualqualifications",
    updateLinkedChildAPI:"api/profile/manage/add/child",
    genderUpdateApiUrl:"api/profile/manage/UpdateProfileGenderDetails",
    getGenderApiUrl: "api/profile/individual/GetAllGenderDetails",
    getNationalityAPI: "api/profile/individual/GetAllNationalityDetails",
    updateNationalityAPI: "api/profile/manage/UpdateProfileNationality",
    updateMedicalInfoAPI: "api/profile/manage/UpdateProfileMedicalInfo",
    updatePlaceOfBirth: "api/profile/manage/placeofbirth",
    refereeRegistrationDetail: "api/RefereeRegistration/registartionseasondetails",
    refereeIntensionApiUrl: "api/RefereeDetails/OptInDetails",
    refereeIntensionUpdateApiUrl: "api/RefereeDetails/OptInDetails",
    faqhelpAPI:"noauth/api/profile/individual/GetFAQDetails",
    verifyRefreeRegAPI: "noauth/api/RefereeRegistration/registrationdetails",
    IndividualMemebrshipApiUrl: "v2/noauth/api/individuals",
    consentApi: "v1/players",
    listOfDisabilities: "v1/disabilities",
    saveDisability: "api/profile/manage/disability",
    childIndividualProfile: "child/api/profile/individual/details",
    childUpdateNameByFuzzySearch: "child/api/profile/manage/updatenamebyfuzzysearch", 
    childLinkedAccount: "child/api/profile/individual/linkedaccounts",
    childUpdatePlaceOfBirth: "child/api/profile/manage/placeofbirth",
    childUpdateNationality: "child/api/profile/manage/UpdateProfileNationality",
    childUpdateGender: "child/api/profile/manage/UpdateProfileGenderDetails",
    childEmergencyContact: "child/api/profile/individual/emergencycontactdetails",
    childUpdateMedicalInfo: "child/api/profile/manage/UpdateProfileMedicalInfo",
    childUpdateAddress:"child/api/profile/manage/childaddress",
   
  childUpdateDob: "child/api/profile/manage/dateofbirth",
  childCFAUpdateMessage: "child/api/profile/notification/user/notes",
  childNotificationRequest: "child/api/notification/notificationrequest",

  childRegistrationSeasonDetails: "api/RefereeRegistration/childregistartionseasondetails",

  childDownloadImageApiUrl: "child/api/Media/Download",
  childDeleteImageApiUrl: "child/api/Media/Delete",
  childUploadImageApiUrl: "child/api/Media/Upload",

  childEmergencyContactUpdate: "child/api/profile/manage/contact",
  childEmailUpdate: "child/api/profile/manage/emailaddress",

  // county related APIs
  IndividualCounty: "api/individual/county/preference",
  getCountyList: "v1/counties",

  // referee consent
  childRefereeConsent: "child/api/profile/manage/refereeregistration/consent",

  // Payment Autorenewal
  paymentAutoRenewalApi: "v1/api/individual/payment/autorenewal/details",

  notificationEmarsysApi: "api/notification/emarsys/trigger",
  emailNotificationEmarsysApi: "api/notification/emarsys/secure/trigger",
  
  // parent remove  
  removeParentNotification: "api/profile/manage/decouple/notification/status",
  updateRemoveParentNotification: "api/profile/manage/decouple/notification",
  removeParentAccount: "api/profile/manage/decouple",

  // referee levels
  refereeLevelsApi: "api/RefereeDetails/ProfessionalDetails",
  childRefereeLevelsApi: "Child/api/RefereeDetails/ProfessionalDetailsWithBST",

  childRefereeIntensionApiUrl: "Child/api/RefereeDetails/OptInDetails",

  // Affiliated County with location update
  affilicatedCountyWithLocationApi: "api/RefereeDetails/WorkLocation",
  childAffilicatedCountyWithLocationApi: "Child/api/RefereeDetails/WorkLocation",

  // Emergency contact for Parent
  emergencyContact: "api/profile/individual/emergencycontactdetails",
  updateEmergencyContact: "api/profile/manage/contact",

  getEDIDetailsApi: "v1/api/EDnI/GetEDnIDetails",
  addEDIDetailsApi: "v1/api/EDnI/AddEDnIDetails",
  deleteEDIDetailsApi: "v1/api/EDnI/DeleteEDnIDetails",
  getEDIQuestionnaireApi: "v1/api/EDnI/GetEDnIQuestionnaire",

  //Logout api
  globalLogoutAPi:"v1/api/User/RevokeSignInSessions",
  validateUserSession:"v1/api/User/ValidateSignInSessions"
}

export const labels = {
  diversityAndInclusion: {
    ediHeading: "Diversity and Inclusion",
  },
  ediQuestionnaireMapping: [
    {
      id: 1,
      category: "genderAtBirth",
    },
    {
      id: 2,
      category: "gender",
    },
    {
      id: 3,
      category: "sexuality",
    },
    {
      id: 4,
      category: "schoolType",
    },
    {
      id: 5,
      category: "parentOccupation",
    },
    {
      id: 6,
      category: "disability",
    },
    {
      id: 7,
      category: "disabilityProblems",
    },
    {
      id: 8,
      category: "pathwayEligibility",
    },
    {
      id: 9,
      category: "ethnicity",
    },
    {
      id: 10,
      category: "religion",
    },
  ],
}

export const externalApplicationUrls = {
  playerRegistration: "#/manageconsent",
  dispensationGuidelines: "https://grassrootstechnology.thefa.com/support/solutions/articles/48001235147-dispensation-faqs"
}

export const b2cPolicies = {
  signup: 'signup',
  login: 'login',
  forgottenemail: 'forgottenemail',
  dontknowmyfan: 'dontknowmyfan',
  logout: 'logout',
  forgotPassword: 'forgotPassword',
  authenticateUser: 'authenticateUser',
  updatePassword: 'updatePassword',
  updateEmail: 'updateEmail',
  updateMobile: 'updateMobile',
  updateAddress: 'updateAddress',
  errorCodes: {
    forgotPassword: 'AADB2C90118'
  }
}
import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { IpsService } from '../../shared/microservices/ips.service';
import { globalConstants } from '../../shared/global-constants';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelperComponent implements OnInit {

  customExpansionHeight: boolean = false;
  questionAnswers: Array<any> = [];
  displayQuestions: boolean = false;
  constructor(private ipsService: IpsService) { }

  ngOnInit() {
    this.runScript();
    this.loadScript(globalConstants.loginHelpBootstrapUrl);
  }

  
  getB2CHelpQuestions() {
    this.ipsService.getB2CHelperQA()
      .subscribe((res:any) => {
        this.questionAnswers = res;
        this.displayQuestions = true;
        if (window.screen.width < 768) {
          this.customExpansionHeight = true;
        }
      });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    body.appendChild(script);
  }

  public runScript() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = `
    function openWidget() { FreshworksWidget('open'); }
    window.fwSettings = { 'widget_id': 48000001359 };
    !function () { if ("function" != typeof window.FreshworksWidget) {
          var n = function () {
              n.q.push(arguments)
           };
              n.q = [],
              window.FreshworksWidget = n
          }
      }()
    openWidget();`;
    script.async = true;
    body.appendChild(script);
  }

}

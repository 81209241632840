import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CssResolver } from '../../shared/services/resolvers/css-resolver';
import { AccountManagementComponent } from './account-management.component';
import { MsalGuard } from '@azure/msal-angular';
import { CheckPolicyComponent } from 'src/app/components/b2c-policy/check-policy.component';
import { b2cPolicies } from 'src/environments/constants';
import { IndividualProfileResolverService } from '../../shared/services/resolvers/profile-resolver.service';

const routes: Routes = [
  {
    path: 'account-management',
    component: AccountManagementComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'profile',
        loadChildren: () =>
          import('./my-account/my-account.module').then(
            (m) => m.MyAccountModule
          ),
        resolve: {
          CssResolver,
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'learning',
        loadChildren: () =>
          import('./learning/learning.module').then((m) => m.LearningModule),
        resolve: {
          resolveProfile: IndividualProfileResolverService,
          CssResolver,
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'dbs-safeguarding',
        loadChildren: () =>
          import('./dbs-safeguarding/dbs-safeguarding.module').then(
            (m) => m.DbsSafeguardingModule
          ),
        resolve: {
          resolveProfile: IndividualProfileResolverService,
          CssResolver,
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'preferences',
        loadChildren: () =>
          import('./preferences/preferences.module').then(
            (m) => m.PreferencesModule
          ),
        /* canActivate: [AgeGuardService],  */
        resolve: {
          resolveProfile: IndividualProfileResolverService,
          CssResolver,
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'diversityAndInclusion',
        loadChildren: () =>
          import('../edi/edi.module').then((m) => m.EdiModule),
        resolve: { CssResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        resolve: { CssResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'logout',
        component: CheckPolicyComponent,
        data: { type: b2cPolicies.logout },
      },

      { path: '**', redirectTo: 'profile' },
    ],
  },
  { path: '**', redirectTo: 'account-management' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountManagementRoutingModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable, timer } from 'rxjs';
import { SuccessPopupService } from '../services/success-popup.service';


@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.css']
})
export class SuccessPopupComponent implements OnInit, OnDestroy {

  showloader: boolean = true;
  private subscription: Subscription = new Subscription();
  private timer: Observable<any> = new Observable<any> ();
  successMessage: Subject<string> = this.successPopupService.successMessage;
  successMsg: string = "";
  isLoading: Subject<boolean> = this.successPopupService.isLoading;
  constructor(private successPopupService: SuccessPopupService) { }
 
  ngOnInit() {
  
    this.successMessage.subscribe(
      data => {
        this.successMsg = data;
        if (this.successMsg != undefined || this.successMsg != null) {
          this.showloader = true;
          this.setTimer();
        }
      }
    )

  }


  setTimer() {
    let time = timer(5000); // 5000 millisecond means 5 seconds
    this.subscription = time.subscribe((data) => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showloader = false;
      this.isLoading.next(false);

    });

  }
  public ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }



}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckYouthService {

  
  isYouth(dob:Date):boolean{
    
   let myAge!: number;
    if (dob != undefined || dob != null) {
      var timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
       myAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      
    }
    if (myAge >= 16) {      
      return false;
    }
    else {      
      return true;
    }
  }

  isLessThanEighteen(dob:Date):boolean{
    let myAge!: number;
   
    if (dob != undefined || dob != null) {
      var timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
       myAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      
    }
    if (myAge >= 18) {      
      return false;
    }
    else {      
      return true;
    }
  }

  isLessThanNineteen(dob:Date):boolean{
    let myAge!: number;
   
    if (dob != undefined || dob != null) {
      let timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
       myAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      
    }
    if (myAge >= 19) {      
      return false;
    }
    else {      
      return true;
    }
  }

  isLessThanFourteen(dob:Date):boolean{
    let myAge!: number;
    if (dob != undefined || dob != null) {
      var timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
       myAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      
    }
    if (myAge >= 14) {      
      return false;
    }
    else {      
      return true;
    }
  }


  isLessThanSixteen(dob:Date):boolean{
    let myAge!: number;
    if (dob != undefined || dob != null) {
      var timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
       myAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);      
    }
    if (myAge < 16) {      
      return true;
    }
    else {      
      return false;
    }
  }
}

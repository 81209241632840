export class GenderModal{
    FanCode!: number;
    GenderId!: number;
  }
export class NationalityModal{
    FanCode!: number;
    NationalityId!: any;
  }
export class DisabilityInfoModal{
    FanCode!: number;
    ParentFanCode !: number;
    DisabilityTypes!: DisabilitySaveModal[];
  }

export class DisabilitySaveModal {
  DisabilityTypeId !: number;
  IsActive!: boolean;
}  
export class MedicalInfoModal{
    FanCode!: number;
    MedicalInfo!: string;
  }

export class Nationality{
    ID!: number;
    Name!: string;
  }
  
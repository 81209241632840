import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountManagementComponent {
  title = 'Account-Management';
  constructor(private router: Router) {}
}

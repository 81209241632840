<div id="help-background">
  <div class="panel" id="panel">
    <div class="panel-layout">
      <div class="panel-layout-row">
        <div id="panel-left">
          <div class="helpbox-header">
            <h1 class="content">Frequently Asked Questions</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-links">
    <ul>
      <li><a rel="noopener noreferrer" href="http://www.thefa.com/public/privacy" target="_blank"
          aria-label="Privacy policy opens in a new tab">Privacy Policy</a></li>
      <li><a rel="noopener noreferrer" href="http://www.thefa.com/public/terms" target="_blank" aria-label="Term of use opens in a new tab">Terms
          of Use</a></li>
    </ul>
  </div>
</div>
import { Injectable,Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public successMessage = new Subject<string>();
  isLoading = new Subject<boolean>();
    show() {
        this.isLoading.next(true);        
    }
    hide() {
        this.isLoading.next(false);
    }
    // overflowHide(){
    //   this.document.body.parentElement.classList.add('cdk-global-scrollblock');
    // }
  constructor(@Inject(DOCUMENT) private document: Document) { }
}

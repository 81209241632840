export class IndividualProfile {
  IndividualProfileId!: number;
  Fan!: number;
  FirstName!: string;
  LastName!: string;
  MiddleName!: string;
  LegalName!: string;
  DateOfBirth!: string;
  IsActive!: boolean;
  Disability!: [];
  IndividualProfileAddress!: IndividualProfileAddress[];
  IndividualProfileEmail!: IndividualProfileEmail[];
  IndividualProfilePhone!: IndividualProfilePhone[];
  IndividualProfilePhoto!: IndividualProfilePhoto[];
  IndividualMembership!: IndividualMembership[];
  AvailableMemberships!: AvailableMemberships[];
  isActiveLeagueMember!: boolean;
  Gender!: string;
  MedicalInformation!: string;
  CountryOfNationality!: string;
  isGenderEdit!: boolean;
  PlaceOfBirth!: string;
  EmergencyContact!: string;
  EmergencyContactNumber!: string;
  RefRegConsent!: boolean;
}

export class BasicInfo {
  Fan!: number;
  FirstName!: string;
  LastName!: string;
  MiddleName!: string;
  LegalName!: string;
  DateOfBirth!: string;
  IsActive!: boolean;
}

export class IndividualProfilePhoto {
  ProfilePhotoId!: number;
  IndividualProfileId!: number;
  PhotoTypeId!: number;
  PhotoPath!: string;
  PhotoType!: PhotoType;
}

export class PhotoType {
  PhotoTypeId!: number;
  PhotoTypeDescription!: string;
}

export  const phoneTypes = { 
"DAYTEL": "previously daytime",
"HMEPHO": "previously home",
"HMEPHO2": "previously home 2",
"MOBI": "previously mobile",
"OTHPHO": "previously other",
"WKPHO": "previously work"
}

export class IndividualProfilePhone {
  ProfilePhoneId!: number;
  IndividualProfileId!: number;
  PhoneNumber!: string;
  PhoneType!: keyof typeof phoneTypes;
  IsPreferred!: boolean;
  IsActive!: boolean;
}

export class IndividualProfileEmail {
  ProfileEmailId!: number;
  IndividualProfileId!: number;
  EmailAddress!: string;
  EmailType!: string;
  IsConfirmed!: boolean;
  IsPreferred!: boolean;
  IsActive!: boolean;
}

export class IndividualProfileAddress {
  ProfileAddressId!: number;
  IndividualProfileId!: number;
  HouseName!: string;
  AddressLine1!: string;
  AddressLine2!: string;
  AddressLine3!: string;
  CityOrTown!: string;
  County!: string;
  PostCode!: string;
  Country!: string;
  IsActive!: boolean;
  IsPreferred!: boolean;
}
export class ImageData {
  FileName!: string;
  FileData!: string;
}

export class IndividualMembership {
  MembershipId!: number;
  IndividualProfileId!: number;
  MembershipTypeId!: number;
  IsActive!: boolean;
  StartDate!: Date;
  ExpiryDate!: Date;
  MembershipType!: {
    MembershipTypeId: number;
    MembershipName: string;
    MembershipUrl: string;
    MembershipSignUpUrl: string;
    MembershipLogoPath: string;
    IsActive: boolean;
  };
}

export class AvailableMemberships {
  MembershipTypeId!: number;
  MembershipName!: string;
  MembershipUrl!: string;
  MembershipSignUpUrl!: string;
  MembershipLogoPath!: string;
  IsActive!: boolean;
}

export class CfaMessageNote {
  FanCode!: number;
  Message!: string;
  NotificationSectionId!: number;
  NotificationSection!: string;
}

export class CfaMessageNotification {
  FanCode!: number;
  NotificationType!: string;
  DataKey1!: string;
  DataKey2!: string;
  DataKey3!: string;
  DataKey4!: string;
  NotificationGenerationDate!: Date;
  NotificationDispatchDueDate!: Date;
  EmailTemplateId!: number;
}

export class DobModel {
  FanCode!: number;
  DateOfBirth!: string;
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-diversity',
    template: '',
    styles: []
})

export class DiversityComponent {

    constructor(private _route: ActivatedRoute, private router:Router) { }

    ngOnInit() {
      localStorage.setItem('callingApp', '');
      localStorage.setItem('callingAppUrl', '');
      localStorage.setItem('app', 'edi');
      this._route.queryParams.subscribe((data) => {
        if (data.callingAppUrl) {
          let strUrl = data.callingAppUrl.trim()
          localStorage.setItem("callingAppUrl", strUrl);
        }
      })
      if (this._route.snapshot.queryParamMap.has("callingApp")) {
        localStorage.setItem(
          "callingApp",
          this._route.snapshot.queryParamMap.get("callingApp")?? ''
        );
      }
      this.router.navigateByUrl('/diverstyInclusion')
    }

   
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { CheckPolicyComponent } from './components/b2c-policy/check-policy.component';
import { b2cPolicies } from '../environments/constants';
import { HelperComponent } from "./components/helper/helper.component";
import { DiversityComponent } from "./components/diversity/diversity.component";

const routes: Routes = [ 
  {
    path: "diverstyInclusion",
    loadChildren: () => import('./modules/edi/edi.module').then(m => m.EdiModule),   
  }, 
  {
    path: "edi",
    component: DiversityComponent
  },
  { path: "signup", component: CheckPolicyComponent, data : {type: b2cPolicies.signup} },
  { path: "forgottenemail", component: CheckPolicyComponent, data : {type: b2cPolicies.forgottenemail} },
  { path: "dontknowmyfan", component: CheckPolicyComponent, data : {type: b2cPolicies.dontknowmyfan}  },
  { path: "forgotPassword", component: CheckPolicyComponent, data : {type: b2cPolicies.forgotPassword} },
  { path: "logout", component: CheckPolicyComponent, data : {type: b2cPolicies.logout} },
  { path: "helper", component: HelperComponent },  /* 
  { path: "globallogout", component: CheckPolicyComponent, data : {type: b2cPolicies.logout} }, */
  {
    path: "",    
    loadChildren: () => import('./modules/account-management/account-management.module').then(m => m.AccountManagementModule),       
   }
];

@NgModule({
  imports:  [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
  
})
export class AppRoutingModule { }

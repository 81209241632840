import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/microservices/navigation.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  data = {
    'FooterHtml': ''
  };

  constructor(private headerfooterService: NavigationService) {
   }

  ngOnInit() {
    this.headerfooterService.getHeaderFooterSubject().subscribe( (response: any) => {
        if (document.getElementsByTagName('footer').length == 0) {
          let footer = document.createRange().createContextualFragment(response['FooterHtml']);
          document.body.appendChild(footer);

          console.log('footer >>>>>>>', footer)
        }
        setTimeout(function () {
          (<HTMLElement>document.querySelectorAll('section.fa-row-strip__footer')[0]).style.backgroundImage = "url('//cdn.thefa.com/thefawebsite/assets/images/bg-texture-white.jpg')";
          (<HTMLElement>document.querySelectorAll('section.fa-row-strip__footer')[0]).style.opacity = "1";
          $('.fa-footer__social-channels__channel').addClass('inactive');
          $('.fa-dropdown__select').parent().parent().find('[data-social-section="' + $('.fa-dropdown__select').val() + '"]').removeClass('inactive');
          $('.fa-dropdown__select').on('change', function (e) {
            $('.fa-footer__social-channels__channel').addClass('inactive');
            $('.fa-dropdown__select').parent().parent().find('[data-social-section="' + $('.fa-dropdown__select').val() + '"]').removeClass('inactive');
          });
          $('.fa-back-to-top__button').on('click', function () {
            $('body,html').animate({ scrollTop: 0 }, 800);
          });
          var tabb = document.getElementsByClassName('fa-back-to-top__button');
          tabb[0].setAttribute('tabindex', '5');

        }, 1000);            
 
    })    
  }


}



import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { SuccessPopupComponent } from '../../shared/success-popup/success-popup.component';
import { AccountManagementRoutingModule } from './account-management-routing.module';
import { AccountManagementComponent } from './account-management.component';
import { FooterComponent } from '../../navigation/footer/footer.component';
import { HeaderComponent } from '../../navigation/header/header.component';

@NgModule({
  declarations: [
    AccountManagementComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    SuccessPopupComponent   
  ],
  imports: [
    AccountManagementRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [Title, DatePipe],
})
export class AccountManagementModule {}

import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "src/environments/environment";


export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.b2cSettings.clientID,
            authority: `${environment.b2cSettings.authUrl}/${environment.b2cSettings.tenant}/${environment.b2cSettings.signInPolicy}`,
            redirectUri: environment.b2cSettings.redirectUri,
            postLogoutRedirectUri: '/',
            navigateToLoginRequestUrl: false,
            knownAuthorities: [
                environment.b2cSettings.knownAuthorities
            ]
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        }   
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [environment.b2cSettings.clientID], //ideally should be b2cScopes as per msal1
            extraQueryParameters: environment.b2cSettings.extraQueryParameters
        }
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>([
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.ipsService, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.mediaService, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.notificationService, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.CRSservice, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.refereeService, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.playerReg, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.referenceDataSubky, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.apiEndPoints.services.referenceDataAuthz, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.paymentServerUrl + environment.apiEndPoints.services.passApi, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.edi.apiEndPoints.services.ediAuthz, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl + environment.edi.apiEndPoints.services.ediSubky, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl +  environment.apiEndPoints.services.ipsAuthzService, [environment.b2cSettings.clientID]],
        [environment.apiEndPoints.serverUrl +  environment.apiEndPoints.services.b2cuseraccount, [environment.b2cSettings.clientID]]
    ]);    
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IndividualMembership } from '../../models/IndividualProfile.model';

import { environment } from '../../../../src/environments/environment';
import { Caps } from '../../models/caps.model';
@Injectable({
  providedIn: 'root',
})
export class CRSservice {
  constructor(private http: HttpClient) {}
  private config = environment.apiEndPoints;

  private csrServerUrl =
    this.config.serverUrl + this.config.services.CRSservice;

  private checkMembershipApi =
    this.csrServerUrl + this.config.endpoints.IndividualMemebrshipApiUrl;
  private capsApi = this.csrServerUrl + this.config.endpoints.capsApi;

  private individualProfileRoleApiUrl =
    this.csrServerUrl + this.config.endpoints.individualProfileRole;

  getMembership(fanCode: string): Observable<IndividualMembership> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<IndividualMembership>(
      `${this.checkMembershipApi}?fanCode=${fanCode}`,
      header
    );
  }
  getCaps(fanCode: string): Observable<Caps> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<Caps>(
      `${this.capsApi}/${fanCode}?fanCode=${fanCode}`,
      header
    );
  }

  getIndividualProfileRole(fanCode: string) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(
      `${this.individualProfileRoleApiUrl}/${fanCode}?fanCode=${fanCode}`,
      header
    );
  }
}
